import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { BrandService } from '../../services/brand.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-customer-select',
	templateUrl: './customer-select.component.html',
	styleUrls: ['./customer-select.component.scss'],
	//animations: [SharedAnimations]
})
export class CustomerSelectComponent implements OnInit {
	customers = [];
	filteredOptions = [];
	searchCustomerControl = new FormControl();
	selected = null;
	selectedId = null;
	constructor(
		public customerService: CustomerService,
		public brandService: BrandService,
		private route: ActivatedRoute
	) { }


	setSelectedCustomer(customerId){
		for(let c of this.customers){
			if(c.id == customerId){
				this.selected = c;
				this.selectedId = this.selected.id;
			}
		}

		this.customerService.setSelectedCustomer(this.selected);
		this.brandService.unselectBrand();
		window.location.reload(true);
	}

	setCustomers(customers){
		this.customers = customers.sort((a,b)=>{
			const n1 = a.name.toLowerCase();
			const n2 = b.name.toLowerCase();
			return n1 == n2 ? 0 : (n1 < n2 ? -1 : 1);
		});
		this.filteredOptions = this.customers;
		if(!this.customerService.selectedCustomer){
			this.customerService.setSelectedCustomer(this.customers[0], false);
		}
		this.selected = this.customerService.selectedCustomer;
		this.selectedId = this.selected.id;
		//console.log(this.selected)
	}

	getCustomerList() {
		this.customerService.getCustomers().subscribe(customers => {
			this.setCustomers(customers)
		});
	}

	ngOnInit() {
		document.addEventListener('newCustomerAdded', () =>{
			this.getCustomerList();
		});

		this.getCustomerList();
		
		this.searchCustomerControl.valueChanges.subscribe(
			(res) => {
				console.log(res);
				this.filteredOptions = this.customers.filter(
					(el) => el.name.toLowerCase().includes(res.toLowerCase())
				)
			}
		)
	}
}
