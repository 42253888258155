import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { take, map } from 'rxjs/operators';
import {Constants} from 'src/app/app-constants';
import { HttpClient } from  '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class UserResolverService implements Resolve <Observable<any>>{
	private ep = Constants.API_ENDPOINT;

	constructor(private  http:  HttpClient) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
		return this.http.get<any>(this.ep + "/app/user")
	}
}