import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { SearchService } from 'src/app/shared/services/search.service';
import { BrandService } from 'src/app/shared/services/brand.service';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { Router, RouteConfigLoadStart, ResolveStart, RouteConfigLoadEnd, ResolveEnd, ActivatedRoute } from '@angular/router';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { UserService } from '../../../services/user.service';
import moment from 'moment-timezone';

@Component({
	selector: 'app-customer-layout',
	templateUrl: './customer-layout.component.html',
	styleUrls: ['./customer-layout.component.scss']
})
export class CustomerLayoutComponent implements OnInit {

		moduleLoading: boolean;
		@ViewChild(PerfectScrollbarDirective, { static: true }) perfectScrollbar: PerfectScrollbarDirective;

		constructor(
			public navService: NavigationService,
			public searchService: SearchService,
			public brandService: BrandService,
			private router: Router,
			private route: ActivatedRoute,
			private user: UserService
		) {
			this.navService.setMenuType('customer')
		}

		ngOnInit() {
			this.router.events.subscribe(event => {
				if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
					this.moduleLoading = true;
				}
				if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
					this.moduleLoading = false;
				}
			});
			console.log("CustomerLayoutComponent loaded")


			moment.locale('th') // @TODO
			if(!this.brandService.getSelectedBrand()){
				this.brandService.setSelectedBrand(this.route.snapshot.data.brandsList[0], false);
			} else {
				moment.tz.setDefault(this.brandService.getSelectedBrand().timezone);
			}
			try{
				console.log(this.brandService.getSelectedBrand().timezone, Math.round(moment().utcOffset() / 60))
			}catch(e){}

			moment.tz.setDefault("Asia/Bangkok"); // @TODO .. 
			this.user.setUser(this.route.snapshot.data.user);
		}

}
