import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';;
import { ICellRendererParams, IAfterGuiAttachedParams } from '@ag-grid-community/all-modules';



@Component({
  selector: 'app-list-actions',
  styles: [
  	'a{color:#0077cc !important;cursor: pointer !important;}',
  	'img{border-radius:50%; width:32px; height:32px}',
  	'.la-menu{display:inline-block; width:50px; text-align:center; font-size:20px}',
  	'.la-menu:after{content:"⋮"}',
  	'.la-menu:hover:after{content:""}',
  	'.la-menu-elements{visibility:hidden}',
  	'.la-menu:hover .la-menu-elements{visibility:visible}',
  	'.la-menu-action{display:inline-block; margin-right:5px; cursor:pointer}'
  ],
  template: `
	<span class=la-menu>
		<span class="la-menu-elements">
			<span [title]="a.title" class="la-menu-action" *ngFor="let a of actions" (click)="a.fnc(id)"><i class="{{a.icon}}"></i></span>
		</span>
	</span>
	`
})

export class ListActionsComponent implements ICellRendererAngularComp {

	actions;
	id;
	agInit(params): void {
		this.id = params.value;
		this.actions = params.actions;
	}

  	refresh(params?: any): boolean {
		return false;
  	}

}