import { Directive, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Directive({
	selector: '[colResizer]'
})
export class ResizeDirective {

	@Output() DragStart = new EventEmitter();
	@Output() Drag = new EventEmitter();
	@Output() DragEnd = new EventEmitter();

	private dragging = false;
	private startAt = 0;
	private endAt = 0;
	private leftCol = null;
	private rightCol = null;
	constructor(el: ElementRef) {
		//	el.nativeElement.style.backgroundColor = 'yellow';
			this.leftCol = el.nativeElement.parentNode.previousSibling;
			this.leftCol.style.backgroundColor = 'yellow';
			this.leftCol.style.width = "100px" //'calc(100vw - 100px)';
			//this.rightCol = el.nativeElement.parentNode.nextSibling;
		}

	@HostListener('mousedown', ['$event'])
	onMousedown(event) {
		if (event.which === 1) {
			this.dragging = true;
			this.DragStart.emit({ originalEvent: event });
			this.startAt = event.clientX;
		}
	}
	@HostListener('document:mouseup', ['$event'])
	onMouseup(event) {
		if (this.dragging) {
			let D = this.endAt- this.startAt;
			this.DragEnd.emit({ originalEvent: event });
			this.endAt = event.clientX;
			console.log(this.endAt- this.startAt)
			this.leftCol.style.width = 'calc(100vw - ' + D + 'px)';
		}
		this.dragging = false;
	}
	@HostListener('document:mousemove', ['$event'])
	onMousemove(event: MouseEvent) {
		if (this.dragging) {
			let D = this.endAt- this.startAt;
			this.leftCol.style.width = 'calc(100vw - ' + D + 'px)';
			//this.rightCol.style.width = 'calc(100vw + ' + D + 'px)';
			this.Drag.emit({ originalEvent: event });
		}
	}
}