import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpResponse,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { BrandService } from './brand.service';
import { CustomerService } from './customer.service';
import { Observable } from 'rxjs/Observable';
import {Router} from '@angular/router';
import { of, throwError } from "rxjs";
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RequestsInterceptor implements HttpInterceptor {
	constructor(public auth: AuthService, private router: Router, public brand: BrandService, public customer: CustomerService) {}

	private handleAuthError(err: HttpErrorResponse): Observable<any> {
				//handle your auth error or rethrow
				if (err.status === 401 || err.status === 403) {
						//navigate /delete cookies or whatever
						this.router.navigateByUrl("/sessions/signin");
						// if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
						return of(err.message); // or EMPTY may be appropriate here
				}
				return throwError(err);
		}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let headers = {};

		if(request.url.startsWith("https://www.instagram.com/")){
			return next.handle(request);
		}

		headers['Authorization'] = `Token ${this.auth.getToken()}`;
		headers['X-Selected-Brand'] = this.brand.selectedBrand ? this.brand.selectedBrand.id : "";
		headers['X-Selected-Customer'] = this.customer.selectedCustomer ? this.customer.selectedCustomer.id : "";

		//const sc = localStorage.getItem('selectedCustomer');
		// if(sc){
		// 	headers['X-Selected-Customer'] = sc;
		// }

		request = request.clone({
			setHeaders: headers
		});
		return next.handle(request).pipe(catchError(x=> this.handleAuthError(x))).pipe(map(e =>{
			if (e instanceof HttpResponse){
				if(e.body.status == "error"){
					window.alert(e.body.error);
					return e.clone({ body: null });
				}
				return e.clone({ body: e.body.data });
			}
		}));
	}
}