import { Component, OnInit,OnChanges,ElementRef,ViewContainerRef, ViewChild, Input,Output,EventEmitter, Renderer2, RendererFactory2 } from '@angular/core';


@Component({
  selector: 'app-floating-panel',
  templateUrl: './floating-panel.component.html',
  styleUrls: ['./floating-panel.component.css'],
  //providers:[StoryListComponent]
})
export class FloatingPanelComponent implements OnInit, OnChanges {
	@Input() visible;
	@Input() modal = false;
	@Output() onCloseRequest = new EventEmitter();
	@Input() marginRight = 10;
	@Input() width = 60;
	@Input() overrideOverflow = false;
	@ViewChild('panelBody', {static:false, read:ElementRef}) panelBody: ElementRef;
	modalBg = null;

	constructor(private renderer: Renderer2){}


	emitCloseRequest(){
		this.onCloseRequest.emit();
	}


	ngOnChanges(changes){
		if('modal' in changes && changes.modal.firstChange == false){
			//console.log(changes.modal);
			if(changes.modal.currentValue === true){
				this.createModal();
			} else {
				this.removeModal();
			}
		} else{
			//console.log(changes)
		}
		if('visible' in changes){
			if(this.panelBody){
				this.panelBody.nativeElement.scrollTop = 0;
			}
			if(changes.visible.currentValue === false){
				this.removeModal();
			}
		}
	}

	ngOnInit() {
	}


  	createModal(){
  		if(!this.modalBg){
			this.modalBg = this.renderer.createElement('div');
			this.renderer.setProperty(this.modalBg, 'style', 'position:absolute;top:0;left:0;height:100%;width:100%;background-color:black;z-index:199;opacity:.03');
			this.renderer.appendChild(document.body, this.modalBg);
		}
  	}

  	removeModal(){
  		if(this.modalBg){
  			this.renderer.removeChild(document.body, this.modalBg);
  			this.modalBg = null;
  		}
  	}

}
