import { NgModule } from '@angular/core';
import { TableExporterComponent } from './table-exporter.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
	],
	declarations: [TableExporterComponent],
	exports: [TableExporterComponent]
})
export class TableExporterModule {

}
