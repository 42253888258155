import { Component, OnInit,Input,Output,EventEmitter  } from '@angular/core';

@Component({
	selector: 'app-table-search',
	templateUrl: './table-search.component.html',
	styleUrls: ['./table-search.component.scss']
})
export class TableSearchComponent implements OnInit {
	@Input() tableApi;
	//@Output() onSuccess = new EventEmitter();
	filterText;
	constructor() {

	}

	setFilter(){
		this.tableApi.table.setQuickFilter(this.filterText);
	}

	ngOnInit() {

	}

}
