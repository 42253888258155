import { Component, OnInit,Input,Output,EventEmitter  } from '@angular/core';

@Component({
	selector: 'app-table-exporter',
	templateUrl: './table-exporter.component.html',
	styleUrls: ['./table-exporter.component.scss']
})
export class TableExporterComponent implements OnInit {
	@Input() tableApi;
	@Input() lazy_loaded: boolean;
	@Output() onSuccess = new EventEmitter();
	@Output() getDataToExport = new EventEmitter();

	constructor() {

	}

	exportLazy() {
		this.getDataToExport.emit();
	}

	export(){
		var includeHeaders = true;
		var tsv = [];
		var hdrs = [];
		// iterate only nodes that pass the filter and ordered by the sort order
		this.tableApi.table.forEachNodeAfterFilterAndSort((rowNode, index) => {
    		let row = [];
    		const cols = rowNode.columnApi.getAllGridColumns();
    		for(let c of cols){
    			//console.log(c.colId)
    			let col = rowNode.columnApi.getColumn(c.colId);
    			if(col && col.visible){
    				if(tsv.length == 0 && includeHeaders){
    					hdrs.push(col.colDef.headerName)
    				}
    				//console.log(col)
    				let d = col.colDef.field.split(".").reduce((a, b) => a[b], rowNode.data);
    				let v = typeof d == "string" ? d.replace(/[\n\r\t]+/g, " ") : d;
    				row.push(v);
    			}
    			//console.log(row.join(" - "))
    		}
    		tsv.push(row.join("\t"));
		});
		//console.log(tsv)
		if(includeHeaders){
			tsv.splice(0, 0, hdrs.join("\t"));
		}
		const inp = document.createElement("textarea");
		inp.value = tsv.join("\n");
		const b = document.getElementsByTagName("body")[0];
		b.appendChild(inp);
		inp.select();
  		inp.setSelectionRange(0, 99999);
  		document.execCommand("copy");
  		b.removeChild(inp);
  		this.onSuccess.emit();
	}

	ngOnInit() {

	}

}
