import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';;
import { ICellRendererParams, IAfterGuiAttachedParams } from '@ag-grid-community/all-modules';



@Component({
  selector: 'app-avatar-renderer',
  styles: [
  	'a{color:#0077cc !important;cursor: pointer !important;}',
  	'img:first-child{border-radius:50%; width:32px; height:32px}',
  	'span{display:inline-block; margin-left:20px}',
  	'.list-action{cursor:pointer}'
  ],
  template: `
	<img src="{{this.url}}"><span class="{{spanClass}}" (click)="onClick()">{{this.text}}</span>&nbsp;
	<img title="verified" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAAsTAAALEwEAmpwYAAABWWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpMwidZAAADEElEQVQ4EW1TTWhUVxT+zr3vvcm8cSYZ89OgC7HQgtgmJIEUG4UiBpJsCtK4c1XaQhauigjdpLiobgR/CApd2V3aUtxI1U1/jNMKVm1RF3Zho6axaRInmczMe+/eezzXNGJLL+9dzj1/7/u+ex7hf9ZbpxZLKqAjFpi5PrH54q6z1YNwdmtlYvNn/02nDcf4NOvZ+aVCfunXetLds48pd4GI5hl0DXCjxFy3bEbX5ufuteW7cjONjjVMkiMwE4h46PTCFhTbPmbGdpcmrxKpHvFDBRGcScDOQk4VyuWeENtfzB+zJ3+efG0l2EBQ56dZ3rW+E5XCvmQpBduUAYbJEgHBJImkw5ZdqqSRLlvU7txs+lolbTE+Pq1zLZ29MGkhWaoDNjPi9o8sqFD2qnh+XG2YZFHqrOkq7R3e6dHTwDmOA65+qkh/KA2L7IwQkiopF4LwEB8IiP42hb5OjSsPDTLWCAh/OmvPqBsfUZ0YC4K3KLn+qzzvRP6MEYrzbpPxRknh6HCM/b05JKKaIy2pKAjFx8pb1Fj9XCt9FWGMmmU+0h/hq90t+KHB2NuucGIsRiEiTFWaqCXMcZSTIn3xp4ny+eciurj1XXKul1wDNS+ZDMCe1yN8v4nwSqsWWITD39bx9YLFUB5kTArHbmhwqjqmvQaamx9Ah28TOxSEz9lZQ91CYfTNFlTXGIek+Mayw0BMSL0+SstYuLyQeUSYZDXY8bgcRMUxuayjinlbmmV2Toi+t1XjSZ1x6W+LnjyhaWFUEIp+uGWd+cSQnRFbWgrqwTOP2oO463JYDPuz5ZpcgeX7on6HAnfIPcpUkNIhcu0xsmryzcz5Ywfw3aSRsJdRWrh8nhTuZqvZTefoIZOmHXFIncWSysSWahbIv2cr2S3h8GBg5KDcgtT5za+d0xyVFhudqTJJYHlEhdEXgmKFlLrtHA8JpJqou5+4+ZtWRd1djv/68gDZFw3W26zvu6eelg254zJnFVWvXUCh9X0/fZWJ8mFP9+Xcf9v+x/KaiLAD5+bil4PPzz6+8f4TfAYqy2vLaKRa7QAAAABJRU5ErkJggg==" *ngIf="isVerified">
	`
})

export class ListAvatarComponent implements ICellRendererAngularComp {

	url;
	text;
	actionFnc = null;
	actionParam = null;
	spanClass = "";
	isVerified = false;
	agInit(params): void {
		this.text = params.value;
		this.url = params.pictureUrlField.split('.').reduce(function(a, b) {
			return a[b];
		}, params.data);
		if(params.action){
			this.actionFnc = params.action.fnc;
			this.actionParam = params.action.field.split('.').reduce(function(a, b) {
				return a[b];
			}, params.data);
			this.spanClass = "list-action";
		}
		if(params.verifiedField){
			this.isVerified = params.verifiedField.split('.').reduce(function(a, b) {
				return a[b];
			}, params.data);;
		} else if(params.data.is_verified){
			this.isVerified = true;
		}
	}

  	refresh(params?: any): boolean {
		return false;
  	}

	onClick(data) {
		if(this.actionFnc){
			this.actionFnc(this.actionParam);
		}
		// if (this.params.onClick instanceof Function) {
		// 	this.params.onClick(data);
		// }
	}
}