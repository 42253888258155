import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnLoadingComponent } from './btn-loading/btn-loading.component';
import { FeatherIconComponent } from './feather-icon/feather-icon.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { SearchModule } from './search/search.module';
import { BrandSelectModule } from './brand-select/brand-select.module';
import { TableExporterModule } from './table-exporter/table-exporter.module';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LayoutsModule } from './layouts/layouts.module';
//import { StoryModule } from "./stories/story.module"
//import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FloatingPanelComponent } from './floating-panel/floating-panel.component';
import { ListAvatarComponent } from './list-components/list-avatar.component';
import { ListCampaignstatusComponent } from './list-components/list-campaignstatus.component';
import { ListClickabletextComponent } from './list-components/list-clickabletext.component';
import { ListActionsComponent } from './list-components/list-actions.component';
import { TableSearchComponent } from './table-search/table-search.component';
import { DatePicker } from './datepicker/datepicker.component';
import { FormsModule } from '@angular/forms';
import {BriefEditorComponent} from './brief-editor/brief-editor.component';
//import { InfluencterDetailsComponent } from './entity-details/influencer-details/influencer-details.component';
//import { MatToolbarModule, MatFormFieldModule, MatSelectModule,MatTabsModule } from '@angular/material';
import {CustomerSelectComponent} from './customer-select/customer-select.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MoodboardEditorComponentComponent } from './moodboard-editor-component/moodboard-editor-component.component';
import { CustomFileUploadComponent } from './custom-file-upload/custom-file-upload.component';
import { ListClickableImgComponent } from './list-components/list-clickable-img.component';

const components = [
  BtnLoadingComponent,
  FeatherIconComponent,
  FloatingPanelComponent,
  ListActionsComponent,
  ListAvatarComponent,
  ListClickabletextComponent,
  DatePicker,
  TableSearchComponent,
  ListCampaignstatusComponent,
  BriefEditorComponent,
  MoodboardEditorComponentComponent,
  CustomFileUploadComponent,
  ListClickableImgComponent
  
  //CustomerSelectComponent // imported by LayoutModule

  //InfluencterDetailsComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SearchModule,
    BrandSelectModule,
    PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    TableExporterModule,
    DragDropModule
    
   // MatSelectModule
    
   // InfiniteScrollModule
   // StoryModule
  ],
  declarations: components,
  exports: components,
  entryComponents:[ListActionsComponent, ListAvatarComponent, ListClickabletextComponent,ListCampaignstatusComponent, ListClickableImgComponent] //, InfluencterDetailsComponent]
})
export class SharedComponentsModule { }
