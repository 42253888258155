import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchModule } from './components/search/search.module';
import { TableExporterModule } from './components/table-exporter/table-exporter.module';
import { BrandSelectModule } from './components/brand-select/brand-select.module';
import { SharedComponentsModule } from './components/shared-components.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { NgxEchartsModule } from 'ngx-echarts';

//import { InfiniteScrollModule } from 'ngx-infinite-scroll';
@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    TableExporterModule,
    ToastrModule.forRoot(),
    NgbModule,
    SearchModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    SharedPipesModule,
    RouterModule,
    BrandSelectModule,
    NgxEchartsModule,
   // InfiniteScrollModule
  ]
})
export class SharedModule { }
