import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { SearchService } from 'src/app/shared/services/search.service';
import { BrandService } from 'src/app/shared/services/brand.service';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { Router, RouteConfigLoadStart, ResolveStart, RouteConfigLoadEnd, ResolveEnd, ActivatedRoute } from '@angular/router';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import moment from 'moment-timezone';
import { UserService } from '../../../services/user.service';
@Component({
	selector: 'app-crm-layout',
	templateUrl: './crm-layout.component.html',
	styleUrls: ['./crm-layout.component.scss']
})
export class CRMLayoutComponent implements OnInit {

		moduleLoading: boolean;
		@ViewChild(PerfectScrollbarDirective, { static: true }) perfectScrollbar: PerfectScrollbarDirective;

		constructor(
			public navService: NavigationService,
			public searchService: SearchService,
			public brandService: BrandService,
			private router: Router,
			private route: ActivatedRoute,
			private user: UserService
		) { 
			this.navService.setMenuType('crm')
		}

		ngOnInit() {
			this.router.events.subscribe(event => {
				if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
					this.moduleLoading = true;
				}
				if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
					this.moduleLoading = false;
				}
			});
			console.log("CRMLayoutComponent loaded")
			// if(!this.brandService.getSelectedBrand()){
			// 	this.brandService.setSelectedBrand(this.route.snapshot.data.brandsList[0], false);
			// }
			moment.locale('th'); // @TODO ..
			moment.tz.setDefault("Asia/Bangkok"); // @TODO ..
			this.user.setUser(this.route.snapshot.data.user);
		}

}
