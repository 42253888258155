import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-query-loading',
	templateUrl: './query-loading.component.html',
	styleUrls: ['./query-loading.component.scss'],
	//animations: [SharedAnimations]
})
export class QueryLoadingComponent implements OnInit {
	loading = false;
	constructor(
	) { 
		document.addEventListener('queryIsLoading', (e) =>{
			//console.log(e)
			this.loading = (e as any).detail.isLoading;

		});
	}


	ngOnInit() {
		
	}

}
