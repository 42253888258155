import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminLayoutSidebarLargeComponent } from './admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import { HeaderSidebarLargeComponent } from './admin-layout-sidebar-large/header-sidebar-large/header-sidebar-large.component';
import { HeaderCRMComponent } from './crm-layout/header-crm/header-crm.component';
import { HeaderCustomerComponent } from './customer-layout/header-customer/header-customer.component';
import { CRMLayoutComponent } from './crm-layout/crm-layout.component';
import { CustomerLayoutComponent } from './customer-layout/customer-layout.component';
import { AdminLayoutSidebarCompactComponent } from './admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { SearchModule } from '../search/search.module';
import { BrandSelectModule } from '../brand-select/brand-select.module';
import { SidebarLargeComponent } from './admin-layout-sidebar-large/sidebar-large/sidebar-large.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SidebarCompactComponent } from './admin-layout-sidebar-compact/sidebar-compact/sidebar-compact.component';
import { HeaderSidebarCompactComponent } from './admin-layout-sidebar-compact/header-sidebar-compact/header-sidebar-compact.component';
import { FooterComponent } from '../footer/footer.component';
import { CustomizerComponent } from '../customizer/customizer.component';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QueryLoadingComponent } from '../query-loading/query-loading.component';
import { MatProgressBarModule } from '@angular/material';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { CustomerSelectComponent } from '../customer-select/customer-select.component';
import { MatToolbarModule, MatFormFieldModule, MatSelectModule,MatTabsModule } from '@angular/material';
const components = [
    HeaderSidebarCompactComponent,
    HeaderCRMComponent,
    HeaderCustomerComponent,
    HeaderSidebarLargeComponent,
    SidebarLargeComponent,
    SidebarCompactComponent,
    FooterComponent,
    CustomizerComponent,
    AdminLayoutSidebarLargeComponent,
    AdminLayoutSidebarCompactComponent,
    CRMLayoutComponent,
    CustomerLayoutComponent,
    AuthLayoutComponent,
    BlankLayoutComponent,
    QueryLoadingComponent,
    UserMenuComponent,
    CustomerSelectComponent
];

@NgModule({
  imports: [
    NgbModule,
    RouterModule,
    FormsModule,
    SearchModule,
    BrandSelectModule,
    SharedPipesModule,
    SharedDirectivesModule,
    PerfectScrollbarModule,
    CommonModule,
    MatProgressBarModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  declarations: components,
  exports: components
})
export class LayoutsModule { }
