import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
import {Constants} from 'src/app/app-constants'
import { Observable }   from 'rxjs/Observable';


// export interface ApiResponse {
// 	status: string;
// 	data: any;
// 	error: string;
// 	errorcode: string;
// }



@Injectable({
	providedIn: 'root'
})
export class CustomerService {

	public selectedCustomer;
	private ep = Constants.API_ENDPOINT;

	constructor(private  http:  HttpClient) {
		let b = window.localStorage.getItem('selectedCustomer');
		if(b){
			this.selectedCustomer = JSON.parse(b);
		}
	}

	unselectCustomer(){
		window.localStorage.removeItem('selectedCustomer');
		this.selectedCustomer = null;
	}

	getSelectedCustomer(){
		return this.selectedCustomer;
	}

	setSelectedCustomer(customer, triggerEvent?){
		//console.log("customer selection changed to "  + customer)
		this.selectedCustomer = customer;
		if(typeof triggerEvent == 'undefined' || triggerEvent !== false){
			let evt = new CustomEvent('customerSelectionChange', {detail:{customer:customer}});
			document.dispatchEvent(evt);
		}
		window.localStorage.setItem('selectedCustomer', JSON.stringify(this.selectedCustomer))
		return true;
	}

	getCustomers(){
		return this.http.get<any>(this.ep + "/app/customer")
	}

	getCustomerDetails(id:number) :Observable<any>{
		return this.http.get<any>(this.ep + '/app/customer/' + id)
	}

	updateCustomer(customer){
		return this.http.patch<any>(this.ep + '/app/customer/' + customer.id, customer);
	}


}
