import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';;
import { ICellRendererParams, IAfterGuiAttachedParams } from '@ag-grid-community/all-modules';



@Component({
	selector: 'app-clickabletext-renderer',
	styles: [
		'a{color:#0077cc !important;cursor: pointer !important;}',
		'span{display:inline-block;cursor:pointer}'
	],
	template: `
	<span (click)="onClick()">{{this.text}}</span>
	`
})


export class ListClickabletextComponent implements ICellRendererAngularComp {

	text;
	actionFnc = null;
	actionParam = null;
	actionData = null; // @TODO remove actionParam
	agInit(params): void {
		this.text = params.value;
		this.actionData = params.data;
		if(params.action){
			this.actionFnc = params.action.fnc;
			if('field' in params.action){
				this.actionParam = params.action.field.split('.').reduce(function(a, b) {
					if(a) {
						return a[b];
					}
				}, params.data);
			}
		}
	}

	refresh(params?: any): boolean {
		return false;
	}

	onClick(data) {
		if(this.actionFnc){
			this.actionFnc(this.actionParam, this.actionData);
		}
	}
}