import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';;
// import { ICellRendererParams, IAfterGuiAttachedParams } from '@ag-grid-community/all-modules';



@Component({
    selector: 'app-clickabletext-renderer',
    styles: [
        '.container{cursor:pointer;}',
        'img{margin-right: 8px;}'
    ],
    template: `
	<div class="container" (click)="redirect(userName)">
        <img src="assets/icons/brand-icons/instagram-icon.svg" alt="">
        <span>{{userName}}</span>
    </div>
	`
})


export class ListClickableImgComponent implements ICellRendererAngularComp {
    userName;

    redirect(userName: string) {
        window.open(`https://instagram.com/${userName}`);
    }

    agInit(params): void {
        this.userName = params.value;
    }

    refresh(params?: any): boolean {
        return false;
    }
}