import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
import {Constants} from 'src/app/app-constants'
import moment from 'moment-timezone';


// export interface ApiResponse {
// 	status: string;
// 	data: any;
// 	error: string;
// 	errorcode: string;
// }



@Injectable({
	providedIn: 'root'
})
export class BrandService {

	public selectedBrand;
	private ep = Constants.API_ENDPOINT;

	constructor(private  http:  HttpClient) {
		let b = window.localStorage.getItem('selectedBrand');
		if(b){
			this.selectedBrand = JSON.parse(b);
		}
	}

	unselectBrand(){
		window.localStorage.removeItem('selectedBrand');
		this.selectedBrand = null;
	}

	getSelectedBrand(){
		if(!this.selectedBrand){
			return null;
		}
		if(!this.selectedBrand.timezone){
			this.selectedBrand.timezone = "Asia/Bangkok";
		}
		return this.selectedBrand;
	}

	setSelectedBrand(brand, triggerEvent?){
		//console.log("brand selection changed to "  + brand)
		if(!brand){
			this.unselectBrand();
			return;
		}
		this.selectedBrand = brand;

		moment.tz.setDefault(this.getSelectedBrand().timezone);
		if(typeof triggerEvent == 'undefined' || triggerEvent !== false){
			let evt = new CustomEvent('brandSelectionChange', {detail:{brand:this.getSelectedBrand()}});
			document.dispatchEvent(evt);
		}
		window.localStorage.setItem('selectedBrand', JSON.stringify(this.selectedBrand))
		return true;
	}

	getBrands(){
		return this.http.get<any>(this.ep + "/app/brand")
	}


}
