import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface moodboardObject {
  image: string,
  label?: string
}

@Component({
  selector: 'app-moodboard-editor-component',
  templateUrl: './moodboard-editor-component.component.html',
  styleUrls: ['./moodboard-editor-component.component.scss']
})


export class MoodboardEditorComponentComponent implements OnInit {

  @Input() moodboardList: moodboardObject[] = [];

  @Output() onCancelRequest = new EventEmitter<any>();
  @Output() onSave = new EventEmitter<moodboardObject[]>();

  numFiles: number = null;
  locked: boolean = false;

  finishedLoading: boolean = false;

  constructor() { }

  onImageUpload(event){
    this.moodboardList.push(event);
    this.quantityCheck();
  }
  
  quantityCheck() {
    if(this.moodboardList.length === this.numFiles) {
      this.locked = true;
    } else if(this.moodboardList.length === 0) {
      this.locked = false;
    }
  }

  quantityRecieved(event) {
    this.numFiles = event;
  }

  removeItem(i) {
    this.moodboardList.splice(i, 1);
    this.quantityCheck();
  }

  entered(event: CdkDragEnter) {
    moveItemInArray(this.moodboardList, event.item.data, event.container.data);
  }

  save() {
    this.onSave.emit(this.moodboardList);
  }

  cancel() {
    this.moodboardList = [];
    this.quantityCheck();
    this.onCancelRequest.emit();
  }

  editLabel(input: HTMLInputElement, index:number) {
    this.moodboardList[index].label = input.value;
  }

  ngOnInit() {
  }

}
