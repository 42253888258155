import { Component, OnInit,ViewChild,ElementRef,Output,Input,EventEmitter,OnChanges } from '@angular/core';
import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment-timezone';
import {Utils} from "src/app/app-utils"

@Component({
	selector: 'app-datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.component.css']
})
export class DatePicker implements OnInit {
	@ViewChild('dpPopup',{static:false}) datePickerPopup;
	@Output() onDateSelected = new EventEmitter();
	@Input() showPresets = true;
	@Input() initialFromDate;
	@Input() initialToDate;
	@Input() displayAsInput = true;
	@Input() showClear = true;

	hoveredDate: NgbDate | null = null;
	fromDate: NgbDate;
	toDate: NgbDate | null = null;
	dateString = ""
	startDate = null;
	Utils = Utils;

	constructor(calendar: NgbCalendar) {
		//this.fromDate = calendar.getNext(calendar.getToday(), 'd', -10);//calendar.getToday();
		//this.toDate = calendar.getToday(); //calendar.getNext(calendar.getToday(), 'd', 10);
	}


  ngOnChanges(changes) {

        //this.doSomething(changes.categoryId.currentValue);
        // You can also use categoryId.previousValue and
        // categoryId.firstChange for comparing old and new values
        console.log(changes)
        if('initialFromDate' in changes || 'initialToDate' in changes){
        	this.setInitials();
        }
    }

	dateToString(date){
		if(date.format)
			return date.format("YYYY-MM-DD");
		let y = date.year;
		let m = date.month < 10 ? `0${date.month}`: date.month;
		let d = date.day < 10 ? `0${date.day}` : date.day;
		return `${y}-${m}-${d}`
	}

	dpSetDateString(){
		let fd = this.dateToString(this.fromDate);//`${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
		let td = this.dateToString(this.toDate);//`${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
		this.dateString = "from " + Utils.formatDate(fd, false) + " to " + Utils.formatDate(td, false);
	}

	dpOnDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
			this.toDate = date;
			this.datePickerPopup.close();
			this.dpSetDateString();
			this.startDate = this.fromDate;
			let ds = this.dateToString(this.fromDate) + "," + this.dateToString(this.toDate);
			this.onDateSelected.emit({string:ds})
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}

	dpIsHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
	}

	dpIsInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	dpIsRange(date: NgbDate) {
		return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.dpIsInside(date) || this.dpIsHovered(date);
	}


	selectPreset(preset){
		var d = moment()
		switch(preset){
			case 'past-5d':
				d.subtract(5, 'days');
				this.dateString = "Past 5 days";
				break;
			case 'past-15d':
				d.subtract(15, 'days');
				this.dateString = "Past 15 days";
				break;
			case 'past-30d':
				d.subtract(30, 'days');
				this.dateString = "Past 30 days";
				break;
			case 'past-6m':
				d.subtract(6, 'months');
				this.dateString = "Past 6 months";
				break;
		}
		this.toDate = null;
		this.fromDate = null;
		this.datePickerPopup.close();
		let ds = this.dateToString(d) + "," + this.dateToString(moment());
		this.onDateSelected.emit({string:ds})
	}

	clear(){
		this.dateString = ""
		this.startDate = null;
		this.toDate = null;
		this.fromDate = null;
		this.datePickerPopup.close();
		this.onDateSelected.emit({string:""})
	}

	setInitials(){
		let ifd = moment(this.initialFromDate)//.toS.split('-');
		console.log(ifd)
		console.log(this.initialFromDate)
		this.fromDate = new NgbDate(ifd.year(), ifd.month() + 1, ifd.date());
		//this.fromDate = new NgbDate(parseInt(ifd[0]), parseInt(ifd[1]), parseInt(ifd[2]));

		let itd = moment(this.initialToDate) //.split('-');
		this.toDate = new NgbDate(itd.year(), itd.month() + 1, itd.date());
		this.dpSetDateString();
	}

	ngOnInit() {
		if(!this.initialFromDate){
			var d = moment().subtract(1, 'months')
			this.startDate =  {year: d.year() , month: d.month()+1};
		} else {
			this.setInitials()
		}
	}


}