import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { delay,tap } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';
import {Constants} from 'src/app/app-constants'
import { BrandService } from './brand.service';
import { CustomerService } from './customer.service';
@Injectable({
	providedIn: "root"
})
export class AuthService {
	//Only for demo purpose
	authenticated = false;
	authEndpoint = Constants.API_ENDPOINT + '/app/login';
	registerEndpoint = Constants.API_ENDPOINT + '/app/customer-registration';

	constructor(private store: LocalStoreService, private router: Router, private httpClient: HttpClient, private brandService: BrandService, private customerService: CustomerService) {
		this.checkAuth();
	}

	checkAuth() {
		this.authenticated = this.store.getItem("access_token") != null;
	}

	// DEPRECATED @TODO use userService instead
	getUser() {
		//return of({});
		return this.httpClient.get<any>(Constants.API_ENDPOINT + "/app/user")
	}

	signin(credentials) {
		return this.httpClient.post<{token:  string}>(this.authEndpoint, credentials).pipe( tap(res => {
			if(!res.token){
				alert("login failed");
				return;
			}
			this.store.setItem('access_token', res.token);
			this.authenticated = true;
		}))
		// this.authenticated = true;
		// this.store.setItem("demo_login_status", true);
		// return of({}).pipe(delay(1500));
	}

	signup(credentials) {
		return this.httpClient.post<any>(this.registerEndpoint, credentials).pipe( tap(res => {
			if(!res.token){
				alert("Registration failed");
				return;
			}
			this.store.setItem('access_token', res.token);
			this.authenticated = true;
		}))
	}


	signout() {
		this.authenticated = false;
		this.brandService.unselectBrand();
		this.customerService.unselectCustomer();
		//this.store.setItem("demo_login_status", false);
		this.store.removeItem('access_token');
		this.router.navigateByUrl("/sessions/signin");
	}

	getToken(){
		return this.store.getItem('access_token');
	}
}
