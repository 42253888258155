import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import { HttpClientModule } from '@angular/common/http';
//import { SnapshotListComponent } from './snapshot-list/snapshot-list.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestsInterceptor } from './shared/services/requests.interceptor';
//import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// import { ListAvatarComponent } from './shared/components/list-components/list-avatar.component';
// import { ListActionsComponent } from './shared/components/list-components/list-actions.component';
//import { FloatingPanelComponent } from './shared/components/floating-panel/floating-panel.component';

@NgModule({
  declarations: [
    AppComponent,
    //ListAvatarComponent,
    //ListActionsComponent,
    //FloatingPanelComponent
    //SnapshotListComponent
  ],
  imports: [
    BrowserModule,
    AgGridModule.withComponents([]),
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    AppRoutingModule
    //InfiniteScrollModule
  ],
  providers: [
  {
      provide: HTTP_INTERCEPTORS, // https://medium.com/@ryanchenkie_40935/angular-authentication-using-the-http-client-and-http-interceptors-2f9d1540eb8
      useClass: RequestsInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  //entryComponents: [ListActionsComponent, ListAvatarComponent /*, FloatingPanelComponent /*, ListSelectionComponent*/],
  //exports:[FloatingPanelComponent]
})
export class AppModule { }
