import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';;
import { ICellRendererParams, IAfterGuiAttachedParams } from '@ag-grid-community/all-modules';



@Component({
  selector: 'app-campaignstatus-renderer',
  styles: [
  	'.status{display:inline-block; border:1px solid #eee; border-radius:50%;width:16px;height:16px',
  	'.status-ok{background-color:#65AB47}',
  	'.status-partial{background-color:#F8F406}',
  	'.status-err{background-color:#DE5916}',
  	'span{display:inline-block}'
  ],
  template: `
	<span class="status {{this.spanClass}}" title="{{this.spanTitle}}"></span>
	`
})


export class ListCampaignstatusComponent implements ICellRendererAngularComp {

	campaign;
	spanClass;
	spanTitle;
	agInit(params): void {
		//console.log(params.data)
		this.campaign = params.campaign();
		if(this.campaign.min_stories <= params.data.campaign_stories && this.campaign.min_posts <= params.data.campaign_posts){
			this.spanClass = "status-ok";
			this.spanTitle = "OK";
		} else if(!params.data.campaign_stories && !params.data.campaign_posts){
			this.spanClass = "status-err";
			this.spanTitle = "No Content!";
		} else {
			this.spanClass="status-partial";
			this.spanTitle = "Partial Posting";
		}

	}

  	refresh(params?: any): boolean {
		return false;
  	}

}