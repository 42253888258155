import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { BrandService } from '../../../../services/brand.service';
import { AuthService } from '../../../../services/auth.service';
import { UserService } from '../../../../services/user.service';
import { BrandSelectModule } from '../../../../components/brand-select/brand-select.module';

@Component({
	selector: 'app-header-customer',
	templateUrl: './header-customer.component.html',
	styleUrls: ['./header-customer.component.scss']
})
export class HeaderCustomerComponent implements OnInit {
		isOperator = false;
		constructor(
			private navService: NavigationService,
			public searchService: SearchService,
			public brandService: BrandService,
			private auth: AuthService,
			private user: UserService
		) {
		}
	
		ngOnInit() {
			this.isOperator = this.user.getUser().is_operator;
		}
	
		toggelSidebar() {
			const state = this.navService.sidebarState;
			var evt = null;
			if (state.childnavOpen && state.sidenavOpen) {

				return state.childnavOpen = false;
			}
			if (!state.childnavOpen && state.sidenavOpen) {
				return state.sidenavOpen = false;
			}
			if (!state.sidenavOpen && !state.childnavOpen) {
					state.sidenavOpen = true;
					// setTimeout(() => {
					//     state.childnavOpen = true;
					// }, 50);
			}

		}
	
		signout() {
			this.auth.signout();
		}

}
