import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-custom-file-upload',
  templateUrl: './custom-file-upload.component.html',
  styleUrls: ['./custom-file-upload.component.scss']
})
export class CustomFileUploadComponent implements OnInit {

  @Input() inputWidth: string = '';
  @Input() inputHeight: string = '';
  @Input() acceptableFiles: string = '';
  @Input() hidingPadding: string = '';
  @Input() controlName: string = '';

  @Output() fileUploaded = new EventEmitter<any>();
  @Output() numFiles = new EventEmitter<any>();

  // Emitter emission variations
  @Input() isMoodboard: boolean = false;
  // Use default reader if any file type is accepted
  @Input() defaultReaderBase64: boolean = false;

  @ViewChild('uploader_input', { static: false }) uploader;

  constructor() { }



  imagePreview(loadedFiles, previewMaxWidth: number) {
    if (loadedFiles && loadedFiles[0]) {
      const numberOfFiles = loadedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = (e: any) => {
            const canvas = document.createElement('canvas');
            const maxWidth = previewMaxWidth;
            const scale = maxWidth / e.target.width;
            if (e.target.width > maxWidth) {
              canvas.width = maxWidth;
              canvas.height = e.target.height * scale;
            } else {
              canvas.width = e.target.width;
              canvas.height = e.target.height;
            }
            const ctx = canvas.getContext('2d');
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
            const base64ResizedIMG = ctx.canvas.toDataURL(e.target, 'image/jpeg');

            this.fileUploaded.emit({
              image: base64ResizedIMG,
              label: 'Label here'
            });

          }
          this.numFiles.emit(numberOfFiles);
        };
        reader.readAsDataURL(loadedFiles[i]);
      }
    }
  }

  onUpload(event: any): void {
    let loadedFiles = event.target.files;
    if (this.isMoodboard) {
      this.imagePreview(loadedFiles, 400);
    } else if (this.defaultReaderBase64) {
      this.readFiles(loadedFiles);
    }
    this.uploader.nativeElement.value = '';
  }

  readFiles(loadedFiles) {
    const numberOfFiles = loadedFiles.length;
    this.numFiles.emit(numberOfFiles);
    for (let i = 0; i < numberOfFiles; i++) {
      this.readAsBase64(loadedFiles[i]).then(val => {
        this.fileUploaded.emit(val);
      });
    }
  }

  readAsBase64(file): Promise<any> {
    const reader = new FileReader();
    const result = new Promise((resolve, reject) => {
      reader.addEventListener('load', function () {
        resolve({
          'file': reader.result,
          'name': file.name
        }
        );
      }, false);
      reader.addEventListener('error', function (event) {
        reject(event);
      }, false);
      reader.readAsDataURL(file);
    });
    return result;
  }


  ngOnInit() {
  }

}
