import { Component, OnInit } from '@angular/core';
import { BrandService } from '../../services/brand.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-brand-select',
	templateUrl: './brand-select.component.html',
	styleUrls: ['./brand-select.component.scss'],
	//animations: [SharedAnimations]
})
export class BrandSelectComponent implements OnInit {
	brands = [];
	selected = {};
	constructor(
		public brandService: BrandService,
		private route: ActivatedRoute
	) { }


	setSelectedBrand(brand){
		this.selected = brand;
		this.brandService.setSelectedBrand(brand);
	}

	setBrands(brands){
		this.brands = brands;
		// if(!this.brandService.selectedBrand){
		// 	this.brandService.setSelectedBrand(this.brands[0], false);
		// }
		this.selected = this.brandService.selectedBrand;
	}

	getBrandList() {
		this.brandService.getBrands().subscribe(brands => {
			this.setBrands(brands)
		});
	}

	ngOnInit() {
		document.addEventListener('newBrandAdded', () =>{
			this.getBrandList();
		});

		//this.getBrandList();
		this.setBrands(this.route.snapshot.data.brandsList); // get data from resolver
	}

}
