import { Component, OnInit } from '@angular/core';
//import { DataLayerService } from '../../services/data-layer.service';
//import { Observable, combineLatest } from 'rxjs';
//import { FormControl } from '@angular/forms';
//import { startWith, debounceTime, switchMap, map } from 'rxjs/operators';
//import { SharedAnimations } from '../../animations/shared-animations';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'app-user-menu',
	templateUrl: './user-menu.component.html',
	styleUrls: ['./user-menu.component.scss'],
	//animations: [SharedAnimations]
})
export class UserMenuComponent implements OnInit {
	username = null;
	isOperator = false;
	constructor(
		public authService: AuthService,
		private user: UserService
	) { }

	signout(e){
		e.preventDefault();
		this.authService.signout();
	}

	ngOnInit() {
		// this.authService.getUser().subscribe(u=>{
		// 	this.username = u.username;
		// })
		this.username = this.user.getUser().username;
		this.isOperator = this.user.getUser().is_operator;
	}

}
