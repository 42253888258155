import moment from 'moment-timezone';

export class Utils{
	public static formatDate(d, showTime=true){
		let ds = "DD/MM/YYYY" + (showTime ? " HH:mm" : "");
		return moment(d).format(ds);
	}

	public static formatNumber(n, keepDecimals?, displayAsPercentage?: boolean) {
		if (n) {
			var f, d;
			if (keepDecimals) {
				f = n.toFixed(2).split(".");
				d = "." + f[1];
				f = f[0];
			} else {
				f = Math.round(n).toString();
				d = "";
			}

			if (displayAsPercentage) {
				return f.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + d + '%';
			} else {
				return f.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + d;
			}
		}
	}

	public static formatCurrency(n, currency){
		return this.formatNumber(n) + (currency ? currency.symbol : "$");
	}

	public static utcOffset(d?){
		return Math.round(moment(d).utcOffset() / 60);
	}

	public static toUtc(d){
		return moment.utc(d);
	}

	public static dateToSysString(d){
		var nd = moment(d, "L");
		// if(!nd.isValid()){
		// 	nd = moment(d, "L");
		// }
		return nd.format("YYYY-MM-DD HH:mm:ssZZ");
	}

	public static humanFormatNumber(n){
		var m = Math.floor(n);
		let s = ['k', 'm', 'b', 't'];
		var i = -1;
		while(m >= 1000){
			m /= 1000;
			i++;
		}
		if(i == -1)
			return m;
		return (m - Math.round(m) > 0.01 ? m.toFixed(1) : Math.round(m)) + s[i];

	}

	public static showLoading(loading){
		let evt = new CustomEvent("queryIsLoading", {detail:{isLoading:loading}});
		document.dispatchEvent(evt);
	}

	public static dateSorterFn(a, b){
		return moment(b).isAfter(a) ? -1 : 1;
	}

	public static async getImageDimensions(url){
		const i = document.createElement('img');
		const body = document.getElementsByTagName('body')[0];
		i.setAttribute('style', 'visibility:hidden;position:absolute;width:0;height:0');
		body.appendChild(i);
		i.src = url;
		await new Promise((resolve, reject) => {
			i.onload = () => resolve();
			i.onerror = () => reject(); // @TODO handle this
		})
		const d = [i.naturalWidth, i.naturalHeight];
		body.removeChild(i);
		return d;
	}

	public static updateTable(api, data){
		if(!data.id){
			return;
		}
		const row = api.table.getRowNode(data.id);
		if(row){
			const newData = {};
			for(let c in row.data){
				newData[c] = typeof data[c] != 'undefined' ? data[c] : row.data[c];
			}
			api.table.updateRowData({update:[newData]});
		} else {
			api.table.updateRowData({add:[data], addIndex:0});
		}
	}

}