import { NgModule } from "@angular/core";
import { BrandSelectComponent } from "./brand-select.component";
import { NgxPaginationModule } from "ngx-pagination";
import { CommonModule } from "@angular/common";
import { MatSelectModule } from "@angular/material";
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    NgbModule,
    //ReactiveFormsModule,
    //NgxPaginationModule
  ],
  declarations: [BrandSelectComponent],
  exports: [BrandSelectComponent],
})
export class BrandSelectModule {}
