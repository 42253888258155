import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { take, map } from 'rxjs/operators';

import { BrandService } from './brand.service';
import { Observable } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class BrandResolverService implements Resolve <Observable<any>>{

	constructor(private bs: BrandService) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
		return this.bs.getBrands() /*.pipe(
			take(1),
			map(userdata => userdata)
		)*/
	}
}