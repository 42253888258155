import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { BriefEditorService } from './brief-editor.service';
import { ActivatedRoute } from '@angular/router';
import {Utils} from 'src/app/app-utils';


@Component({
	selector: 'app-brief-editor',
	templateUrl: './brief-editor.component.html',
	styleUrls: ['./brief-editor.component.scss'],
	//animations: [SharedAnimations]
})

export class BriefEditorComponent implements OnInit {
	@Input() brief;
	@Output() briefChange = new EventEmitter();

	//brief = null;

	constructor(
		public brandService: BriefEditorService,
		private route: ActivatedRoute
	) { }


	ngOnInit() {
	}

	triggerChange(){
		this.briefChange.emit(this.brief);
	}


	onChange(e){
		this.triggerChange();
	}

	// print(){
	// 	console.log(this.brief)
	// }

	// newBrief(){
	// 	this.brief = JSON.parse(JSON.stringify(blankBrief));
	// }

	// getElement(str){
	// 	return str.split('.').reduce((a, b) => a[b], this.brief);
	// }

	removeListElement(el, index){
		if(!window.confirm("Remove element?")){
			return;
		}
		el.splice(index, 1);
		//this.getElement(el).splice(index, 1);
		this.triggerChange();
	}

	addListElement(el, child){
		el.push(child || "")
		this.triggerChange();
	}


	onMoodboardImageFileChange(e){
		const reader = new FileReader();
		if(e.target.files && e.target.files.length) {
			const [file] = e.target.files;
			reader.readAsDataURL(file);
			reader.onload = () => {
				this.brief.moodboard.link = reader.result as string;
				Utils.getImageDimensions(this.brief.moodboard.link).then(d => {
					this.brief.moodboard.dimensions = d;
					this.triggerChange();
				})
			};
		}
	}

	trackBy(i, e){
		return i;
	}
}
