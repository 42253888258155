import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BrandResolverService } from './shared/services/brand-resolver.service';
import { UserResolverService } from './shared/services/user-resolver.service';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import { CRMLayoutComponent } from './shared/components/layouts/crm-layout/crm-layout.component';
import { CustomerLayoutComponent } from './shared/components/layouts/customer-layout/customer-layout.component';
//import {StoryComponent} from './views/stories/story.component';

const adminRoutes: Routes = [
		{
			path: 'influencers',
			loadChildren: () => import('./views/influencers/influencers.module').then(m => m.InfluencersModule)
		},
		{
			path: 'brands',
			loadChildren: () => import('./views/brands/brands.module').then(m => m.BrandsModule)
		},
		{
			path: 'campaigns',
			loadChildren: () => import('./views/campaigns/campaigns.module').then(m => m.CampaignsModule)
		},
		{
			path: 'events',
			loadChildren: () => import('./views/events/events.module').then(m => m.EventsModule)
		},
		{
			path: 'stories',
			loadChildren: () => import('./views/stories/story.module').then(m => m.StoryModule)
		},
		{
			path: 'posts',
			loadChildren: () => import('./views/posts/posts.module').then(m => m.PostsModule)
		},
		{
			path: 'dashboard',
			loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
		},
		{
			path: 'reports',
			loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule)
		},
		{
			path: 'account',
			loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
		},
		{
			path: 'uikits',
			loadChildren: () => import('./views/ui-kits/ui-kits.module').then(m => m.UiKitsModule)
		},
		{
			path: 'forms',
			loadChildren: () => import('./views/forms/forms.module').then(m => m.AppFormsModule)
		},
		{
			path: 'invoice',
			loadChildren: () => import('./views/invoice/invoice.module').then(m => m.InvoiceModule)
		},
		{
			path: 'inbox',
			loadChildren: () => import('./views/inbox/inbox.module').then(m => m.InboxModule)
		},
		{
			path: 'calendar',
			loadChildren: () => import('./views/calendar/calendar.module').then(m => m.CalendarAppModule)
		},
		{
			path: 'chat',
			loadChildren: () => import('./views/chat/chat.module').then(m => m.ChatModule)
		},
		{
			path: 'tables',
			loadChildren: () => import('./views/data-tables/data-tables.module').then(m => m.DataTablesModule)
		},
		{
			path: 'pages',
			loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
		},
		{
				path: 'icons',
				loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
		}
	];


const CRMRoutes: Routes = [
		{
			path: '',
			redirectTo: 'customers/list',
			pathMatch: 'full'
		},
		{
			path: 'customers',
			loadChildren: () => import('./crm-views/customers/customers.module').then(m => m.CustomersModule)
		},
		{
			path: 'creators',
			loadChildren: () => import('./crm-views/creators/creators.module').then(m => m.CreatorsModule)
		},
		{
			path: 'requests',
			loadChildren: () => import('./crm-views/requests/requests.module').then(m => m.RequestsModule)
		},
		{
			path: 'tasks',
			loadChildren: () => import('./crm-views/tasks/tasks.module').then(m => m.TasksModule)
		},
		{
			path: 'notifications',
			loadChildren: () => import('./crm-views/notifications/notifications.module').then(m => m.NotificationsModule)
		},

		{
			path: 'active-campaigns',
			loadChildren: () => import('./crm-views/active-campaigns/active-campaigns.module').then(m => m.ActiveCampaignsModule)
		},

];



const CustomerRoutes: Routes = [
		{
			path: '',
			redirectTo: 'dashboard',
			pathMatch: 'full'
		},
		{
			path: 'dashboard',
			loadChildren: () => import('./customer-views/customer-dashboard/customer-dashboard.module').then(m => m.CustomerDashboardModule)
		},
		

		{
			path: 'stories',
			loadChildren: () => import('./views/stories/story.module').then(m => m.StoryModule)
		},
		{
			path: 'posts',
			loadChildren: () => import('./views/posts/posts.module').then(m => m.PostsModule)
		},
		{
			path: 'profile',
			loadChildren: () => import('./customer-views/customer-profile/customer-profile.module').then(m => m.CustomerProfileModule)
		},
		{
			path: 'scouted-kol',
			loadChildren: () => import('./customer-views/scouted-kol/scouted-kol.module').then(m => m.ScoutedKolModule)
		},
];


const routes: Routes = [
	// {
	//   path: 'vulnerabilities',
	//   //component: VulnerabilityComponent,
	//   module:import('./views/sessions/sessions.module').then(m => m.SessionsModule)
	//  // canActivate: [AuthGaurd],
	//   //children: adminRoutes
	// },
	{
		path: '',
		redirectTo: 'dashboard/brand',
		pathMatch: 'full'
	},
	{
		path: '',
		component: AuthLayoutComponent,
		children: [
			{
				path: 'sessions',
				loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
			}
		]
	},
	{
		path: 'crm',
		component: CRMLayoutComponent,
		canActivate: [AuthGaurd],
		children: CRMRoutes,
		resolve: {
			user: UserResolverService
		}
	},
	{
		path: 'customer',
		component: CustomerLayoutComponent,
		canActivate: [AuthGaurd],
		children: CustomerRoutes,
		resolve: {
			brandsList: BrandResolverService,
			user: UserResolverService
		}
	},
	{
		path: '',
		component: BlankLayoutComponent,
		children: [
			{
				path: 'others',
				loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
			}
		]
	},
	{
		path: '',
		component: AdminLayoutSidebarLargeComponent,
		canActivate: [AuthGaurd],
		//runGuardsAndResolvers: 'always',
		children: adminRoutes,
		resolve: {
			brandsList: BrandResolverService,
			user: UserResolverService
		}
	},
	{
		path: '**',
		redirectTo: 'others/404'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true /*, onSameUrlNavigation: 'reload' */ })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
