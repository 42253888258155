import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}

export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

class SidebarState implements ISidebarState{
    private _sidenavOpen?: boolean;
    childnavOpen: boolean;
    constructor(s, c){
        this._sidenavOpen = s;
        this.childnavOpen = c;
    }
    get sidenavOpen() { return this._sidenavOpen }
    set sidenavOpen(v:boolean){
        this._sidenavOpen = v;
        let evt = new CustomEvent(v ? 'sidebarOpen' : 'sidebarClose', {});
        document.dispatchEvent(evt);
    }
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    // public sidebarState: SidebarState = {
    //     sidenavOpen: true,
    //     childnavOpen: false
    // };
    public sidebarState = new SidebarState(true, false);
    public menuType = null;

    constructor() {
        this.setMenuType(null);
    }

    setMenuType(t){
        if(this.menuType == t){
            return;
        }
        this.menuType = t;
        if(t == 'crm'){
            this.menuItems.next(this.CRMMenu);
        } else if(t == 'customer'){
            this.menuItems.next(this.CustomerMenu);
        } else {
            this.menuItems.next(this.defaultMenu);
        }
    }

    CRMMenu: IMenuItem[] = [
        {
            name: 'Customers',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-a-brand',
            state: '/crm/customers/list'
        },
        {
            name: 'Creators',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-a-influencer',
            state: '/crm/creators/list'
        },
        {
            name: 'Requests',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-Ticket',
            state: '/crm/requests/list'
        },
        {
            name: 'Tasks',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-Check',
            state: '/crm/tasks/list'
        },
        {
            name: 'Notifications',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-Paper-Plane',
            state: '/crm/notifications/list'
        },
        {
            name: 'Campaigns',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-a-campaign',
            state: '/crm/active-campaigns/list'
        },
    ]


    CustomerMenu: IMenuItem[] = [
        {
            name: 'Dashboard',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/customer/dashboard'
        },
        // {
        //     name: 'Stories',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //     type: 'link',
        //     icon: 'i-a-story',
        //     state: '/customer/stories/list'
        // },
        // {
        //     name: 'Posts',
        //     description: '600+ premium icons',
        //     type: 'link',
        //     icon: 'i-a-post',
        //     state: '/customer/posts/list'
        // },
        {
            name: 'Scouted KOL',
            description: 'Lorem ipsum dolor sit amet',
            type: 'link',
            icon: 'i-a-influencer',
            state: '/customer/scouted-kol'
        },

        // {
        //     name: 'Notifications',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //     type: 'link',
        //     icon: 'i-a-influencer',
        //     state: '/customer/tmp'
        // },
        // {
        //     name: 'Billing',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //     type: 'link',
        //     icon: 'i-a-campaign',
        //     state: '/crm/requests/list'
        // },
    ]


    defaultMenu: IMenuItem[] = [
        {
            name: 'Dashboard',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/dashboard/brand'
            // sub: [
            //     { icon: 'i-Clock-3', name: 'Brand', state: '/dashboard/brand', type: 'link' },
            //     { icon: 'i-Clock-3', name: 'Version 1', state: '/dashboard/v1', type: 'link' },
            //     { icon: 'i-Clock-4', name: 'Version 2', state: '/dashboard/v2', type: 'link' },
            //     { icon: 'i-Over-Time', name: 'Version 3', state: '/dashboard/v3', type: 'link' },
            //     { icon: 'i-Clock', name: 'Version 4', state: '/dashboard/v4', type: 'link' },
            // ]
        },

        // {
        //     name: 'Stories',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //     //type: 'dropDown',
        //     icon: 'i-Eye',
        //     state: '/stories/snapshots',
        //     type: 'link'

        // },

        {
            name: 'Stories',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-a-story',
            state: '/stories/list'
            // sub: [
            //     { icon: 'i-Over-Time', name: 'Snapshots', state: '/stories/snapshots', type: 'link' },
            //     { icon: 'i-Cool-Guy', name: 'Users', state: '/stories/snapshots', type: 'link' }
            // ]

        },
        {
            name: 'Posts',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-a-post',
            state: '/posts/list'
        },
        {
            name: 'Influencers',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-a-influencer',
            state: '/influencers/list'
        },

        {
            name: 'Campaigns',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-a-campaign',
            state: '/campaigns/list'
        },

        // {
        //     name: 'Events',
        //     description: '600+ premium icons',
        //     type: 'link',
        //     icon: 'i-a-event',
        //     state: '/events/list'
        // },


        // {
        //     name: 'Mentions',
        //     description: '600+ premium icons',
        //     type: 'link',
        //     icon: 'i-a-mention',
        //     state: '/events/list'
        // },

        // {
        //     name: 'Hashtags',
        //     description: '600+ premium icons',
        //     type: 'link',
        //     icon: 'i-a-hashtag',
        //     state: '/events/list'
        // },

        {
            name: 'Brands',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-a-brand',
            state: '/brands/list'
        },
        {
            name: 'Reports',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-Diploma-2',
            state: '/reports/list'
        },
        // {
        //     name: 'Influencers',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //     type: 'dropDown',
        //     icon: 'i-Cool-Guy',
        //     sub: [
        //         { icon: 'i-Safe-Box', name: 'Monitored', state: '/influencers', type: 'link' },
        //         { icon: 'i-Computer-Secure', name: 'Allowed', state: '/invoice', type: 'link' },
        //         { icon: 'i-Approved-Window', name: 'Blocked', state: '/chat', type: 'link' },
        //     ]
        // },

        // {
        //     name: 'Connectors',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //     type: 'dropDown',
        //     icon: 'i-Sync',
        //     sub: [
        //         { icon: 'i-Add-File', name: 'Exposed Feeds', state: '/invoice', type: 'link' },
        //         { icon: 'i-Email', name: 'Remote Sources', state: '/inbox', type: 'link' },
        //         { icon: 'i-Calendar', name: 'Cron', state: '/calendar', type: 'link' },
        //     ]
        // },



        // {
        //     name: 'Apps',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //     type: 'dropDown',
        //     icon: 'i-Computer-Secure',
        //     sub: [
        //         //{ icon: 'i-Add-File', name: 'Invoice Builder', state: '/invoice', type: 'link' },
        //         { icon: 'i-Email', name: 'Inbox', state: '/inbox', type: 'link' },
        //         { icon: 'i-Speach-Bubble-3', name: 'Chat', state: '/chat', type: 'link' },
        //         { icon: 'i-Calendar', name: 'Calendar', state: '/calendar', type: 'link' },
        //     ]
        // },

        // {
        //     name: 'Settings',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //     type: 'link',
        //     state:"/settings/main",
        //     icon: 'i-Gears',
        //     // sub: [
        //     //     { icon: 'i-Add-User', name: 'Application', state: '/sessions/signup', type: 'link' },
        //     //     { icon: 'i-Find-User', name: 'System', state: '/sessions/forgot', type: 'link' },
        //     //     { icon: 'i-Checked-User', name: 'Application Users', state: '/sessions/signin', type: 'link' },
        //     // ]
        // },













/*

        {
            name: 'Sessions',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Administrator',
            sub: [
                { icon: 'i-Add-User', name: 'Sign up', state: '/sessions/signup', type: 'link' },
                { icon: 'i-Checked-User', name: 'Sign in', state: '/sessions/signin', type: 'link' },
                { icon: 'i-Find-User', name: 'Forgot', state: '/sessions/forgot', type: 'link' }
            ]
        },

        {
            name: 'UI kits',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
            type: 'dropDown',
            icon: 'i-Library',
            sub: [
                { icon: 'i-Bell', name: 'Alerts', state: '/uikits/alerts', type: 'link' },
                { icon: 'i-Split-Horizontal-2-Window', name: 'Accordions', state: '/uikits/accordions', type: 'link' },
                { icon: 'i-Medal-2', name: 'Badges', state: '/uikits/badges', type: 'link' },
                {
                    icon: 'i-Arrow-Right-in-Circle',
                    name: 'Buttons',
                    type: 'dropDown',
                    sub: [
                        { name: 'Bootstrap Buttons', state: '/uikits/buttons', type: 'link' },
                        { name: 'Loding Buttons', state: '/uikits/buttons-loading', type: 'link' }
                    ]
                },
                { icon: 'i-ID-Card', name: 'Cards', state: '/uikits/cards', type: 'link' },
                { icon: 'i-Line-Chart-2', name: 'Cards metrics', state: '/uikits/cards-metrics', type: 'link' },
                { icon: 'i-Credit-Card', name: 'Cards widget', state: '/uikits/cards-widget', type: 'link' },
                { icon: 'i-Full-Cart', name: 'Cards ecommerce', state: '/uikits/cards-ecommerce', type: 'link' },
                { icon: 'i-Duplicate-Window', name: 'Modals', state: '/uikits/modals', type: 'link' },
                { icon: 'i-Speach-Bubble-3', name: 'Popover', state: '/uikits/popover', type: 'link' },
                { icon: 'i-Like', name: 'Rating', state: '/uikits/rating', type: 'link' },
                { icon: 'i-Loading-3', name: 'Loaders', state: '/uikits/loaders', type: 'link' },
            ]
        },

        {
            name: 'Forms',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-File-Clipboard-File--Text',
            sub: [
                { icon: 'i-File-Clipboard-Text--Image', name: 'Basic components', state: '/forms/basic', type: 'link' },
                { icon: 'i-Split-Vertical', name: 'Form layouts', state: '/forms/layouts', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Input Group', state: '/forms/input-group', type: 'link' },
                { icon: 'i-File-Edit', name: 'Input Mask', state: '/forms/input-mask', type: 'link' },
                { icon: 'i-Tag-2', name: 'Tag Input', state: '/forms/tag-input', type: 'link' },
                { icon: 'i-Width-Window', name: 'Wizard', state: '/forms/wizard', type: 'link' },
                { icon: 'i-Crop-2', name: 'Image Cropper', state: '/forms/img-cropper', type: 'link' },
            ]
        },
        {
            name: 'Data Tables',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-File-Horizontal-Text',
            sub: [
                { icon: 'i-File-Horizontal-Text', name: 'List', state: '/tables/list', type: 'link' },
                { icon: 'i-Full-View-Window', name: 'Fullscreen', state: '/tables/full', type: 'link' },
                { icon: 'i-Code-Window', name: 'Paging', state: '/tables/paging', type: 'link' },
                { icon: 'i-Filter-2', name: 'Filter', state: '/tables/filter', type: 'link' },
            ]
        },

        {
            name: 'Pages',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Windows-2',
            sub: [
                { icon: 'i-Male', name: 'User Profile', state: '/pages/profile', type: 'link' }
            ]
        },
        {
            name: 'Icons',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-Cloud-Sun',
            state: '/icons/iconsmind'
        },
        {
            name: 'Others',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Double-Tap',
            sub: [
                { icon: 'i-Error-404-Window', name: 'Not found', state: '/others/404', type: 'link' }
            ]
        },
        {
            name: 'Doc',
            type: 'extLink',
            tooltip: 'Documentation',
            icon: 'i-Safe-Box1',
            state: 'http://demos.ui-lib.com/gull-doc'
        }
        */
    ];


    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    //menuItems = new BehaviorSubject<IMenuItem[]>();
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(menuType: string) {
    //     console.log("UUUuuuuuuuuu")
    //     if(this.menuType == 'crm'){
            
    //     } else {

    //     }
      // switch (userType) {
      //   case 'admin':
      //     this.menuItems.next(this.adminMenu);
      //     break;
      //   case 'user':
      //     this.menuItems.next(this.userMenu);
      //     break;
      //   default:
      //     this.menuItems.next(this.defaultMenu);
      // }
    //}
}
