import { Injectable } from '@angular/core';
// import { HttpClient } from  '@angular/common/http';
// import {Constants} from 'src/app/app-constants'
// import moment from 'moment-timezone';


@Injectable({
	providedIn: 'root'
})
export class UserService {
	private user;
	constructor() {
	}

	getUser(){
		return this.user;
	}

	setUser(user){
		return this.user = user;
	}
}
