import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
import {Constants} from 'src/app/app-constants'


@Injectable({
  providedIn: 'root'
})
export class BriefEditorService {

  private ep = Constants.API_ENDPOINT;

  constructor(private  http:  HttpClient) {
  }

  getBrands(){
  	return this.http.get<any>(this.ep + "/app/brand")
  }


}
